<template>
  <div class="has-addons password">
    <input :type="showPassword ? 'text' : 'password'" class="input"
           :name="name || `password_${_uid}`" :placeholder="placeholder"
           readonly
           onfocus="this.removeAttribute('readonly');"
           v-model="input"/>
    <div class="icon" @click="togglePassword">
      <i class="far fa-eye" v-if="!showPassword"></i>
      <i class="far fa-eye-slash" v-else></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordInput",
  props: {
    label: {
      default: '',
    },
    name: {
      default: null,
    },
    value: {
      default: null,
    },
    placeholder: {
      default: '',
    },
  },
  data: () => ({
    showPassword: false,
    input: null,
  }),
  watch: {
    value() {
      this.input = this.value;
    },
    input() {
      this.emit();
    },
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    emit() {
      this.$emit('input', this.input);
      this.$emit('send', this.input);
    },
  },
  mounted() {
    if (this.value) {
      this.input = this.value;
    }
  },
}
</script>

<style lang="scss" scoped>
.has-addons {
  &.password {
    position: relative;

    input {
      padding-right: 44px;
    }

    .icon {
      position: absolute;
      right: 10px;
      top: 6px;
      cursor: pointer;
    }
  }
}
</style>
